@font-face {
  font-family: 'RubikLight';
  src: local('RubikLight'), url(./fonts/RubikLight.ttf) format('truetype');
}

@font-face {
  font-family: 'RubikBold';
  src: local('RubikBold'), url(./fonts/RubikBold.ttf) format('truetype');
}

@font-face {
  font-family: 'KarlaBold';
  src: local('KarlaBold'), url(./fonts/KarlaBold.ttf) format('truetype');
}

@font-face {
  font-family: 'KarlaRegular';
  src: local('KarlaRegular'), url(./fonts/KarlaRegular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'RubikLight', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
